/* .leaflet-container {
  margin-top: 50%;
  height: 310px;
  width: 25%;
} */

/* @media only screen and (max-width: 600px) {
  .leaflet-container {
    height: 10px;
    width: 10%;
  }
} */

.leaflet-container {
  height: 310px;
  width: 100%;
}

.home {
  margin: 2%;
}
